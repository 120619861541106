<template>
  <div class="banner-section">
    <banner-carousel
      v-if="isCarousel && banners.length > 0"
      :data="banners"
    />
    <banner-img
      v-else-if="randomBanner"
      :data="randomBanner"
    />
  </div>
</template>

<script>
import {  mapActions, mapGetters } from "vuex";
import BannerCarousel from "./BannerCarousel";
import BannerImg from "./BannerImg";
export default {
  name: 'BannerSection',
  components: {BannerImg, BannerCarousel},
  props: {
    location: {
      type: String,
      required: true
    },
    isCarousel: {
      type: Boolean,
      default: true
    },
  },
  created() {
    let params = {
      locations: this.location
    };
    this.getBannersList({
      params: params
    });
  },
  computed: {
    ...mapGetters('banner', {
      locationBanners: 'locationBanners',
    }),
    banners() {
      let banners = this.locationBanners(this.location);
      banners = banners || [];
      return banners;
    },
    randomBanner() {
      let randomBanner = this.banners
        ? this.banners[Math.floor(Math.random() * this.banners.length)]
        : null;
      return randomBanner;
    },
  },
  methods: {
    ...mapActions("banner", {
      getBannersList: "getBannersList"
    })
  }
};
</script>

<style lang="scss">
  .banner-section {
    margin-bottom: 10px;
  }
</style>