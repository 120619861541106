<template>
  <div 
    class="header-banner df"
    @click="redirectByLink(data.link_url)"
  >
    <div class="dynamic-item df">
      <div class="banner-data">
        <div
          class="banner-image"
          :style="{
            'background-image':
              `url('${data.banner_full_url}')`,
          }"
        >
          <img :src="data.banner_full_url"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerImg',
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  computed: {},
  created() {},
  methods: {
    redirectByLink(link) {
      if (link) {
        let resolved = this.$router.resolve(link);
        if (resolved.route && resolved.route.name != '404') {
          this.$router.push(resolved.route);
        } else {
          window.location.href = this.addProtocol(link);
        }
      }
    },
    addProtocol(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url;
      }
      return url;
    }
  }
};
</script>

<style lang="scss" scoped>
 .header-banner {
    /*background-color: #c6c5cb;*/
    /*min-height: 200px;*/
    /*height: 200px;*/
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    max-width: 100%;
    overflow: hidden;
    .dynamic-item {
      height: 100%;
      width: 100%;
      color: #002036;
      text-decoration: none;
      display: block;
      .banner-data {
        height: 100%;
        width: 100%;
        .banner-image {
          height: 100%;
          background: no-repeat;
          background-size: contain;
          background-position: center top;
          img {
            max-height: 300px;
            max-width: 100%;
            display: block;
            visibility: hidden;
          }
        }
      }
    }
  }
</style>