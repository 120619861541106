<template>
  <modal
    :width="550"
    :adaptive="true"
    class="cartModal CEModal modal-scroll-bar"
    name="cartModal"
  >
    <div class="modal-header">
      <img
        src="/assets/img/icons/check-circle-light.svg"
        class="check-icon"
        alt
      />
      <h4>{{ $t('Added to cart') }}</h4>
      <span class="fs16"
        >( {{ cartModalData.total_unique_count }}
        {{ $t('Items') }} )</span
      >
      <button class="button modal-close_btn" @click="$emit('closeModal')">
        <img src="/assets/img/icons/times-light-orange.svg" alt />
      </button>
    </div>
    <div class="modal-content d-flex">
      <img
        :src="cartModalData.image || '/new-assets/img/shaniv-logo.png'"
        class="product-image"
        alt=""
      />
      <div class="mx-15">
        <h4 class="mb-5" v-if="isPackageModal">{{ cartModalData.name }}</h4>
        <h4 class="mb-5" v-else>{{ cartModalData[currentLang + '_name'] }}</h4>
        <p class="mb-5 fs16" v-if="!isPackageModal">
          {{ cartModalData[currentLang + '_description'] }}
        </p>
        <div class="d-flex mb-7 fs16">
          <p v-if="cartModalData.SPEC13" class="mr-5">
            <b>{{ $t('Content') }}: </b>
            <span>{{ cartModalData.SPEC13 }}</span>
          </p>
          <p class="">
            <b>{{ $t('Quantity') }}: </b>
            <span> {{ cartModalData.added_quantity }}</span>
          </p>
        </div>
        <div class="d-flex fs20 weight-700">
          <p>
            <span>{{ cartModalData.package_discount_price }} </span>
            <span class="fs16">₪</span>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer mb-5">
      <p class="weight-700 mt-5">
        <span class="fs16">{{ $t('Total added to shopping cart') }}: </span>
        <span class="fs20">{{ cartModalData.added_total_price }} </span>
        <span>₪</span>
      </p>
      <div class="text-center">
        <p class="weight-700 color-orange">
          <span class="fs14">{{ $t('Total in cart') }}: </span>
          <span>{{ cartModalData.total_price }} </span>
          <span class="fs14">₪</span>
        </p>
        <router-link to="/cart" class="text-decoration-none">
          <button class="primary-btn to-office_btn" @click="$emit('closeModal')">
            {{ $t('To the box office') }}
          </button>
        </router-link>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'CartModal',
  props: {
    cartModalData: Object,
    isPackageModal: Boolean
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss">
.cartModal .modal-content .product-image {
  max-width: 100px;
  max-height: 150px;
  object-fit: contain;
}
.cartModal .text-center {
  height: auto !important;
}
</style>