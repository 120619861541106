<template>
  <carousel
    :perPage="1"
    :autoplay="true"
    paginationActiveColor="#002036"
    class="banner-carousel"
    :loop="true"
    :centerMode="true"
    :autoplayTimeout="5000"
  >
    <slide v-for="(item, index) in data" :key="index">
      <banner-img :data="item"/>
    </slide>
  </carousel>
</template>

<script>
import BannerImg from "./BannerImg";
export default {
  name: 'BannerCarousel',
    components: {BannerImg},
    props: {
      data: {
        type: Array,
        required: true,
      },
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
  .banner-carousel {
    .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: 0 !important;
        .VueCarousel-dot {
          margin-top: 0 !important;
          border-radius: 0 !important;
        }
      }
    }
  }
</style>